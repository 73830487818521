import React, { useState } from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { columns, rows } from "./data"

const Rates = () => {
  const [rates, setRates] = useState(null)

  const handleValidSubmit = () => {
    console.log("Hello World!")
  }
  const data = {
    columns,
    rows,
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="Rates" />
          <Row>
            <Col md={6}>
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleValidSubmit}
              >
                <div className="form-group">
                  <AvField
                    name="currenEmail"
                    label="Enter Country Name: (minimum of 4 letters)"
                    className="form-control"
                    placeholder="Enter country"
                    type="text"
                    required
                  />
                </div>
              </AvForm>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Country Rates</CardTitle>

                  <MDBDataTable responsive hover bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Rates.propTypes = {
  t: PropTypes.any,
}

export default Rates
