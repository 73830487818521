import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import CallsModal from "./CallsModal"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { callLogs } from "helpers/requests"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const CallsLog = () => {
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])
  const toggleModal = () => {
    setModal(!modal)
  }

  const handleChange = date => {
    setStartDate(date)
    console.log(date.toLocaleDateString("en-CA"))
  }
  const handleChange2 = date => {
    setEndDate(date)
  }

  useEffect(() => {
    const getCallsLogs = async () => {
      setLoading(true)
      console.log(endDate?.toLocaleDateString("en-CA"))
      const data = {
        orgId: "939001",
        userIpAddress: "127.0.0.1",
        userGlobalId: user?.userGlobalId,
        startDate: startDate?.toLocaleDateString("en-CA") || null,
        endDate: endDate?.toLocaleDateString("en-CA") || null,
        userDeviceType: "Windows 10",
        userBrowserType: "Chrome",
        userRefererUrl: "www.google.com",
      }
      console.log(data)

      const log = await callLogs(data)
      const Callslog = log?.data?.returnedRecords
      console.log(Callslog)
      if (Callslog) setLogs(Callslog)
      setLoading(false)
    }
    getCallsLogs()
  }, [endDate])
  const handleAmountBilled = billed => {
    if (billed === "-0") return "0.00"
    return Number(billed).toFixed(2)
  }

  const calcLocalDate = startTime => {
    let splitDate = new Date(startTime).toDateString().split(" ")
    return (
      splitDate[0] +
      " " +
      splitDate[1] +
      " " +
      splitDate[2] +
      "," +
      " " +
      splitDate[3]
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Calls"} breadcrumbItem={"Calls Log"} />
          <Row>
            <Col>
              <React.Fragment>
                <CallsModal isOpen={modal} toggle={toggleModal} />
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <CardTitle className="mb-4">Manage Call Log</CardTitle>

                      <Form className="mb-3">
                        <Row>
                          <div className="col-xl col-sm-6">
                            <FormGroup className="mt-3 mb-0">
                              <Label>Start Date :</Label>
                              <DatePicker
                                selected={startDate}
                                onChange={handleChange}
                                className="form-control"
                                placeholderText="Select date"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-xl col-sm-6">
                            <FormGroup className="mt-3 mb-0">
                              <Label>End Date :</Label>
                              <DatePicker
                                selected={endDate}
                                onChange={handleChange2}
                                className="form-control"
                                placeholderText="Select date"
                              />
                            </FormGroup>
                          </div>
                        </Row>
                      </Form>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "20px" }}>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  &nbsp;
                                </label>
                              </div>
                            </th>

                            <th>Destination</th>
                            <th>Duration (seconds)</th>
                            <th>Rate (per min)</th>
                            <th>Amount Billed ($)</th>
                            <th>Date</th>
                            <th>Country Operator</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading && (
                            <SweetAlert
                              title="Loading"
                              timeout={2000}
                              showConfirm={false}
                              onConfirm={() => setLoading(null)}
                            >
                              <div
                                className="spinner-grow text-primary m-1"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </SweetAlert>
                          )}
                          {logs.map((transaction, key) => (
                            <tr key={"_tr_" + key}>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={transaction.callerId}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={transaction.callerId}
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </td>
                              <td className="text-body font-weight-bold">
                                {transaction.destinationCalled}
                              </td>
                              <td>{transaction.durationInSeconds}</td>
                              <td>{transaction.rate}</td>
                              <td>
                                {handleAmountBilled(transaction.amountBilled)}
                              </td>

                              <td>{calcLocalDate(transaction.startTime)}</td>
                              <td>
                                <i
                                  className={
                                    "fab " +
                                    transaction.countryOperator +
                                    " mr-1"
                                  }
                                ></i>{" "}
                                {transaction.countryOperator}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CallsLog.propTypes = {
  t: PropTypes.any,
}

export default CallsLog
