import axios from "axios"

axios.interceptors.request.use(
  request => {
    if (request.url.includes("checkout") || request.url.includes("billing")) {
      request.headers["X-USER-ID"] = localStorage.getItem("token")
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(request => {
  console.log(request.config)
  console.log("This is the request")
})

const Axios = axios.create({
  baseURL: "https://apix.vomozflex.com/web/apix/v4/",
})

export const config = {
  auth: {
    username: "sodiq.akanmu@vomoz.com",
    password: "BiyCL34eDf2W!P3acer",
  },
  headers: {
    "Content-Type": "application/json",
  },
}

export default Axios
