import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import img7 from "assets/images/product/img-7.png"
import img4 from "assets/images/product/img-4.png"
import { AvField, AvForm } from "availity-reactstrap-validation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select"
import { assignLocalNumber, availableLocalNumbers } from "helpers/requests"
import { random } from "lodash-es"
const user = JSON.parse(localStorage.getItem("userData")) || {}


const AssignLocalNumber = props => {
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [options, setOptions] = useState([])
  const [localNumber, setLocalNumber] = useState(null)
  const [localDetails, setLocalDetails] = useState({
    destination: "",
    nickname: "",
  })
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const handleLocalNumberSelect = event => {
    setLocalNumber(event)
  }

  const handleLocalDetails = ({ target: { name, value } }) => {
    setLocalDetails({ ...localDetails, [name]: value })
  }

  let optionGroup = [
    {
      options,
    },
  ]
  useEffect(() => {
    console.log(availableNumbers)
    setOptions(
      availableNumbers.map(local => ({
        label: local.localNumber,
        value: local.localNumber,
        id: local.id,
      }))
    )
  }, [availableNumbers])

  useEffect(() => {
    let idEd
    if (availableLocalNumbers.length < 1)
      idEd = availableLocalNumbers.filter(
        num => num.localNumber === localNumber
      )
    console.log(idEd)
    console.log(localNumber)
  }, [availableLocalNumbers])

  useEffect(() => {
    console.log("how are you")
    const getAvailableLocalNumbers = async () => {
      const user = JSON.parse(localStorage.getItem("userData")) || {}

      let data = {
        orgId: "939001",
        userGlobalId: user?.userGlobalId,
        userIpAddress: "127.0.0.1",
        userDeviceType: "Windows 10",
        userBrowserType: "Chrome",
        userRefererUrl: "www.google.com",
      }

      const available = await availableLocalNumbers(data)
      const AvailableNumbers = available?.data?.returnedRecords
      if (AvailableNumbers) setAvailableNumbers(AvailableNumbers)
    }

    getAvailableLocalNumbers()
  }, [])

  const handleLocalNumberSubmit = async event => {
    toggle()
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: localNumber?.id,
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }
    const Assigned = await assignLocalNumber({ ...data, ...localDetails })
    if (Assigned?.success)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Added",
        dynamic_description: "Local Number has been successfully added.",
      })
    else
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Assigned?.message,
      })
  }

  const { isOpen, toggle } = props
  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Assign Local Number</ModalHeader>
          <Form onSubmit={handleLocalNumberSubmit}>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <FormGroup className="select2-container">
                    <Label>Select Local Number</Label>
                    <Select
                      value={localNumber}
                      onChange={event => handleLocalNumberSelect(event)}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                      name="findMYID"
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-email-Input">
                      International Mobile Number
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-email-Input"
                      placeholder={"Enter Mobile No."}
                      name="destination"
                      onChange={handleLocalDetails}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-email-Input">Nickname</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-name-Input"
                      placeholder={"Name"}
                      name="nickname"
                      onChange={handleLocalDetails}
                    />
                  </FormGroup>
                </Col>{" "}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                color="primary"
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </>
  )
}

AssignLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignLocalNumber
