import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import "./styles.scss"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import logolight from "assets/images/logo-light.png"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import classnames from "classnames"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"
// import { orders } from "./data"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import localNumberColumns from "./localNumberColumns"
import AssignLocalNumber from "./components/assignLocalNumbers"
import EditLocalNumber from "./components/editLocalNumber"
import { deleteLocalNumber, localNumbersAssigned } from "helpers/requests"
// import { getCryptoOrders } from "store/crypto/actions"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const CryptoOrders = props => {
  const [loading, setLoading] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [activeTab, setActiveTab] = useState("1")
  const [orders, setOrders] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [editModalInfo, setEditModalInfo] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [icon, setIcon] = useState(null)
  const [deleteB, setDeleteB] = useState("")

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const toggleEditModal = order => {
    setEditModalInfo(order)
    setEditModal(!editModal)
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    const widthen = window.innerWidth
    console.log(widthen)

    if (widthen < 500) setIcon(true)
    else setIcon(null)
  })

  useEffect(() => {
    console.log("mounted")
    const getAssignedLocalNumbers = async () => {
      setLoading(true)
      const data = {
        orgId: "939001",
        userIpAddress: "127.0.0.1",
        userGlobalId: user?.userGlobalId,
        userDeviceType: "Windows 10",
        userBrowserType: "Chrome",
        userRefererUrl: "www.google.com",
      }

      const Assigned = await localNumbersAssigned(data)
      const servedOrders = Assigned?.data?.returnedRecords
      console.log(servedOrders)
      // if (servedOrders) setOrders(servedOrders.splice(1, 100))
      if (servedOrders) {
        setOrders(servedOrders)
        setLoading(null)
      }
    }
    getAssignedLocalNumbers()
    return null
    console.log(orders)
  }, [])

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id })
  }

  // const { orders } = props
  const status = {
    completed: (
      <span className="badge badge-success font-size-10">Completed</span>
    ),
    pending: <span className="badge badge-warning font-size-10">Pending</span>,
    failed: <span className="badge badge-danger font-size-10">Failed</span>,
  }

  const getRealDate = date => {
    const dateStr = new Date(date).toDateString()
    const timeStr = new Date(date).toLocaleTimeString()

    let splitDate = dateStr.split(" ")
    return (
      <>

        {splitDate[0] +
          " " +
          splitDate[1] +
          " " +
          splitDate[2] +
          "," +
          " " +
          splitDate[3]  
          }
          <br/>
          {timeStr}
      </>
    )
  }

  const data = {
    columns: localNumberColumns,
    rows: map(orders, order => ({
      ...order,
      date: getRealDate(order.date),
      status: status[order.status],

      action: (
        <>
          {!icon ? (
            <div className="local-number-edit-delete-button">
              {" "}
              <Button className="local-number-edit-button" color="primary" onClick={() => toggleEditModal(order)}>
                Edit
              </Button>
              <Button
                color="primary"
                onClick={() => setInfo({ confirm: true, id: order.id })}
                id="sa-params"
                className="ml-3 local-number-delete-button"
              >
                {deleteB === order.id ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  </>
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          ) : (
            <>
              {" "}
              <button
                type="button"
                className="btn noti-icon waves-effect"
                onClick={() => toggleEditModal(order)}
              >
                <i className="bx bx-pencil" />
              </button>
              <button
                className="btn noti-icon waves-effect"
                onClick={() => setInfo({ confirm: true, id: order.id })}
              >
                <i className="bx bx-trash" />
              </button>
            </>
          )}
        </>
      ),

      // clickEvent: order => toggleEditModal(order),
    })),
  }

  const handleLocalNumberDelete = async event => {
    setDeleteB(info?.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: info?.id,
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }
    const Deleted = await deleteLocalNumber(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: "Local Number has been successfully deleted.",
      })
      setOrders(orders.filter(order => order.id != info?.id))
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <div className="spinner-grow text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </SweetAlert>
        )}
        <AssignLocalNumber isOpen={addModal} toggle={toggleAddModal} />
        <EditLocalNumber
          isOpen={editModal}
          toggle={toggleEditModal}
          editInfo={editModalInfo}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Numbers" breadcrumbItem="Local Numbers" />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="card-title mb-3">Local Numbers</h4>

                    <Button
                      type="button"
                      color="success"
                      className="w-md local-number-add-number"
                      onClick={toggleAddModal}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      <span>Add Number</span>
                    </Button>
                  </div>
                  {orders.length > 1 && (
                    <MDBDataTable
                      responsive
                      bordered
                      data={data}
                      className="mt-4"
                      pagesAmount={4}
                      entriesOptions={[100, 200, 500, 1000]}
                      entrieslabel="How Many?"
                      entries={50}
                    />
                  )}

                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleLocalNumberDelete}
                        onCancel={() =>
                          setInfo({
                            confirm: false,
                            error_dlg: true,
                            dynamic_title: "Cancelled",
                            dynamic_description: "Process has been terminated!",
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// CryptoOrders.propTypes = {
//   orders: PropTypes.array,
//   onGetOrders: PropTypes.func,
// }

// const mapStateToProps = ({ crypto }) => ({
//   orders: crypto.orders,
// })

// const mapDispatchToProps = dispatch => ({
//   onGetOrders: () => dispatch(getCryptoOrders()),
// })

export default withRouter(CryptoOrders)
