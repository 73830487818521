import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { authProtectedRoutes, publicRoutes, misRoutes } from "./routes/"
import AppRoute from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import PublicLayout from "components/NonAuthLayout"
import "./assets/scss/theme.scss"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout

    const width = window.innerWidth
    if (width < 500) layoutCls = HorizontalLayout

    // switch (this.props.layout.layoutType) {
    //   case "horizontal":
    //     layoutCls = HorizontalLayout
    //     break
    //   default:
    //     layoutCls = VerticalLayout
    //     break
    // }
    return layoutCls
  }

  // onRoutechange() {
  //   
  //   setTimeout(() => {
  //     if (document.getElementsByClassName("mm-active").length > 0) {
  //       console.log(ref.current.el);
  //       const currentPosition = document.getElementsByClassName("mm-active")[0]
  //         .offsetTop
  //         console.log(currentPosition)
  //       if (currentPosition > 500)
  //         this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
  //           currentPosition + 300
  //     }
  //   }, 300)
  // }

  render() {
    const Layout = this.getLayout()

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={PublicLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={route.path === "/lock-screen" ? PublicLayout : Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
}

export default connect(mapStateToProps, null)(App)
