import React, { Component } from "react"

import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import EcommerceOrdersModal from "../MCEcommerce/EcommerceOrders/EcommerceOrdersModal"

class ManageCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      transactions: [
        {
          id: "1",
          orderId: "#VF2540",
          billingName: "Neal Matthews",
          Date: "+12345678912",
          email: "neal.matthews@gmail.com",
          total: "$700",
          location: "Maryland, United States",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "2",
          orderId: "#VF2541",
          billingName: "Jamal Burnett",
          Date: "+12345678945",
          email: "jamalburnett@gmail.com",
          total: "$380",
          location: "Los Angeles, United States",
          badgeClass: "danger",
          paymentStatus: "Chargeback",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "3",
          orderId: "#VF2542",
          billingName: "Juan Mitchell",
          Date: "+872399678945",
          email: "juan_mitchell@gmail.com",
          total: "$384",
          location: "Paris, France",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "4",
          orderId: "#VF2543",
          billingName: "Barry Dick",
          Date: "+4472399678945",
          email: "barry.dick@gmail.com",
          total: "$412",
          location: "London, United Kingdom",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "5",
          orderId: "#VF2544",
          billingName: "Ronald Taylor",
          Date: "+13399678945",
          email: "ronald.taylor@gmail.com",
          total: "$404",
          location: "Washington, United States",
          badgeClass: "warning",
          paymentStatus: "Refund",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "6",
          orderId: "#VF2545",
          billingName: "Jacob Hunter",
          Date: "+972399678945",
          email: "jacob.hunter@yahoo.com",
          total: "$392",
          location: "Texas, United States",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "7",
          orderId: "#VF2546",
          billingName: "Abdullahi Seidou",
          Date: "+1232399678945",
          email: "abdullahiseidou@hotmail.com",
          total: "$404",
          location: "Ottawa, Canada",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "8",
          orderId: "#VF2547",
          billingName: "Tariq Adebayo",
          Date: "+4412399678945",
          email: "tariq.adebayo@gmail.com",
          total: "$1235",
          location: "London, United Kingdom",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "9",
          orderId: "#VF2548",
          billingName: "John James",
          Date: "+972399678945",
          email: "john.james@gmail.com",
          total: "$6412",
          location: "Manchester, United Kingdom",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "10",
          orderId: "#VF2549",
          billingName: "Chukwuma Obi",
          Date: "+972399678945",
          email: "chukwumaobi@gmail.com",
          total: "$5320",
          location: "Los Angeles, United States",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
      ],
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <EcommerceOrdersModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        />
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Top Customers</CardTitle>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Vomoz ID</th>
                    <th>Customer Name</th>
                    <th>Phone Number</th>
                    <th>Email Address</th>
                    <th>Location</th>
                    <th>Wallet Balance</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.transactions.map((transaction, key) => (
                    <tr key={"_tr_" + key}>
                      <td>
                        <p>{transaction.id}</p>
                      </td>
                      <td>
                        <Link to="#" className="text-body font-weight-bold">
                          {" "}
                          {transaction.orderId}{" "}
                        </Link>{" "}
                      </td>
                      <td>{transaction.billingName}</td>
                      <td>{transaction.Date}</td>
                      <td>{transaction.email}</td>
                      <td>{transaction.location}</td>
                      <td>{transaction.total}</td>

                      {/* <td>
                        <Badge
                          className={
                            "font-size-12 badge-soft-" + transaction.badgeClass
                          }
                          color={transaction.badgeClass}
                          pill
                        >
                          {transaction.paymentStatus}
                        </Badge>
                      </td>
                      <td>
                        <i
                          className={"fab " + transaction.methodIcon + " mr-1"}
                        ></i>{" "}
                        {transaction.paymentMethod}
                      </td> */}
                      <td>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light"
                          // onClick={this.togglemodal}
                          onClick={this.toggleModal}
                        >
                          Customer Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default ManageCustomer
