import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import avatar1 from "assets/images/users/avatar-1.jpg"
import profileImg from "assets/images/profile-img.svg"
import { userAccountParams } from "store/actions"

const WelcomeComp = ({ user, loading, onGetAccountParams }) => {
  const [accountBalance, setAccountBalance] = useState(0)
  const [rewardPoints, setRewardPoints] = useState(0)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData")) || {}
    console.log(user)
    console.log("Here I am")
    if (user) {
      const data = {
        orgId: "939001",
        userGlobalId: user?.userGlobalId,
        userIpAddress: "127.0.0.1",
        userDeviceType: "Windows 10",
        userBrowserType: "Chrome",
        userRefererUrl: "www.google.com",
      }
      onGetAccountParams(data)
    }
  }, [])

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setAccountBalance(user.accountBalance)
      setRewardPoints(user.rewardPointsBalance)
    }
    console.log(user)
  }, [user])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Admin Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {firstName} {lastName}
              </h5>
              <p className="text-muted mb-0 text-truncate">
                Frontend Developer
              </p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">
                      {" "}
                      {Number(rewardPoints).toFixed(0)}
                    </h5>
                    <p className="text-muted mb-0">Points</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">
                      ${Number(accountBalance).toFixed(2)}
                    </h5>

                    <p className="text-muted mb-0">Balance</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to="/account/calls-log"
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    View Calls Log <i className="mdi mdi-arrow-right ml-1" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  user: PropTypes.any,
  accountParams: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.AccountParams.user,
  loading: state.AccountParams.loading,
})

const mapDispatchToProps = dispatch => ({
  onGetAccountParams: data => dispatch(userAccountParams(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeComp)
