import React from "react"
import { Redirect } from "react-router-dom"

// Protected Routes

// Protected Routes (Dashboard)
import Dashboard from "pages/Dashboard"

// Protected Routes (Manage User Routes)
import AuthorizedCountries from "pages/AuthorizedCountries"
import BuyCredit from "pages/BuyCredit"
import CallerIds from "pages/PinlessNumber"
import FollowMeNumbers from "pages/FollowMeNumber"
import LocalNumbers from "pages/LocalNumbers"
import ManageCallLog from "pages/CallsLog"
import ManageCustomers from "pages/ManageCustomer"
import Customer from "pages/Settings"
import TransferCredit from "pages/TransferCredit"
import VoucherRecharge from "pages/VoucherRecharge"

// Protected Routes (Components Routes)
import ManageEmail from "pages/Email/EmailInbox"
import ManageRegistration from "pages/ManageRegistration"

// Protected Routes (Services Routes)
import AccessNumbers from "pages/AccessNumbers"
//import Blog from "pages/Blog"
import BlogList from "pages/BlogList"
import BlogGrid from "pages/BlogGrid"
import BlogDetails from "pages/BlogDetails"

import PartnerRegistration from "pages/BecomeAPartner"
import PartnerList from "pages/PartnerList"
import Rates from "pages/Rates"
import SocialMediaAccounts from "pages/FollowUs"

// Protected Routes (Settings Routes)
import ChangePassword from "pages/ChangePassword"
import PIN from "pages/PIN"
import UpdateEmail from "pages/UpdateEmail"
import UserProfile from "pages/Profile"

// Protected Routes (Other Routes)
import Contact from "pages/Contact"
import Schedule from "pages/Schedule"

// Public Routes
import Login from "pages/Authentication/Login"
import Register from "pages/Authentication/Register"
import ResetPassword from "pages/Authentication/ResetPassword"
import MobileVerification from "pages/Authentication/auth-mobile-verification"
import MobileTwoStep from "pages/Authentication/mobile-two-step-verification"
import EmailTwoStep from "pages/Authentication/email-two-step-verification"
import ForgetPassword from "pages/Authentication/ForgotPassword"
import LockScreen from "pages/Authentication/auth-lock-screen"
import ConfirmMobile from "pages/Authentication/confirm-mail"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  {
    path: "/manage-users/authorized-countries",
    component: AuthorizedCountries,
  },
  { path: "/manage-users/buy-call-credit", component: BuyCredit },
  { path: "/manage-users/caller-ids", component: CallerIds },
  { path: "/manage-users/follow-me-numbers", component: FollowMeNumbers },
  { path: "/manage-users/local-numbers", component: LocalNumbers },
  { path: "/manage-users/call-log", component: ManageCallLog },
  { path: "/manage-users/customer", component: ManageCustomers },
  { path: "/manage-users/customers", component: Customer },
  { path: "/manage-users/transfer-call-credit", component: TransferCredit },
  { path: "/manage-users/voucher-recharge", component: VoucherRecharge },

  { path: "/components/emails", component: ManageEmail },
  { path: "/components/registrations", component: ManageRegistration },

  { path: "/services/access-numbers", component: AccessNumbers },
  //{ path: "/services/blog", component: Blog },
  { path: "/services/blog-list", component: BlogList },
  { path: "/services/blog-grid", component: BlogGrid },
  { path: "/services/blog-details", component: BlogDetails },
  { path: "/services/partner-registration", component: PartnerRegistration },
  { path: "/services/partner-list", component: PartnerList },
  { path: "/services/rates", component: Rates },
  { path: "/services/social", component: SocialMediaAccounts },

  { path: "/settings/user-profile", component: UserProfile },

  { path: "/account/update-email", component: UpdateEmail },
  { path: "/account/change-password", component: ChangePassword },
  { path: "/account/contact", component: Contact },
  { path: "/account/pin", component: PIN },
  { path: "/account/schedule", component: Schedule },

  { path: "/lock-screen", component: LockScreen },

  // this route should be at the end of all other routes
  { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const misRoutes = []

const publicRoutes = [
  // Input Mobile Number to Recieve Code
  { path: "/mobile-verification", component: MobileVerification },

  // Enter Code sent to mobile
  { path: "/auth-mobile", component: MobileTwoStep },

  // Sign Up after Mobile Verification

  { path: "/register", component: Register },

  // Enter Code sent to Email
  { path: "/auth-email", component: EmailTwoStep },

  // Login after Sign Up
  { path: "/login", component: Login },

  // Input email to get code sent to email
  { path: "/forgot-password", component: ForgetPassword },

  //Password Reset
  // Change Password from email link
  { path: "/reset-password", component: ResetPassword },
]

export { authProtectedRoutes, publicRoutes, misRoutes }
