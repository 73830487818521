import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const TransferCredit = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Number"} breadcrumbItem={"Follow Me"} />
          <Row className="justify-content-center">
            <Col lg={7}>
              <Row className="mb-2">
                <Col sm="4"></Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                    >
                      <i className="mdi mdi-plus mr-1" />
                      View Follow-Me
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Now you can talk more!</CardTitle>

                  <AvForm>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-firstname-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Select a Follow-Me Number
                      </Label>
                      <Col sm={9}>
                        <div className="form-group">
                          <AvField
                            type="select"
                            name="select"
                            helpMessage="How do you want to represent us?"
                          >
                            <option>Partner</option>
                            <option>Vendpr</option>
                            <option>Vending Matchin</option>
                            <option>Vacation</option>
                            <option>More</option>
                          </AvField>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Your International Phone Number
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="email"
                          className="form-control"
                          id="horizontal-email-Input"
                          placeholder="Enter Amount you want to transfer"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-password-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Your Return date
                      </Label>
                      <Col sm={9}>
                        <AvField
                          type="date"
                          name="date"
                          className="form-control"
                          id="horizontal-password-Input"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        If I didn't pick up my phone:
                      </Label>
                      <Col sm={9}>
                        <div className="custom-control custom-checkbox custom-checkbox-outline custom-checkbox-info mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck-outlinecolor3"
                            // checked={this.state.defaultOutlineInfo}
                            // onChange={e => {
                            //   this.setState({
                            //     defaultOutlineInfo: e.target.checked,
                            //   })
                            // }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck-outlinecolor3"
                          >
                            Hang Up
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-checkbox-outline custom-checkbox-info mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck-outlinecolor3"
                            // checked={this.state.defaultOutlineInfo}
                            // onChange={e => {
                            //   this.setState({
                            //     defaultOutlineInfo: e.target.checked,
                            //   })
                            // }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck-outlinecolor3"
                          >
                            Go to my Vomoz voicemail
                          </label>
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row justify-content-start ">
                      <Col sm={2}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Set Up
                          </Button>
                        </div>
                      </Col>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TransferCredit.propTypes = {
  t: PropTypes.any,
}

export default TransferCredit
