const cryptoOrderColumn = [
  {
    field: "id",
    label: "ID",
    sort: "asc",
  },
  {
    field: "userContact",
    label: "User Contact",
    sort: "asc",
    width: 136,
  },
  {
    field: "amount",
    label: "Amount",
    sort: "asc",
    width: 100,
  },
  {
    field: "status",
    label: "Status",
  },
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 136,
  },
]

export default cryptoOrderColumn
