import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  CardSubtitle,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const VoucherRecharge = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Credit"} breadcrumbItem={"Voucher Recharge"} />
          <Row className="justify-content-center">
            <Col lg={7}>
              <Row className="mb-2">
                <Col sm="4"></Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                    >
                      <i className="mdi mdi-plus mr-1" />
                      Recharge History
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Load a VomozFlex Top-up voucher
                  </CardTitle>
                  <CardSubtitle>
                    VomozFlex's recharge vouchers are those cards that look like
                    phone cards. You normally find them at African stores or gas
                    stations. Click here to see one.
                  </CardSubtitle>
                  <br />
                  <AvForm>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-firstname-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Enter Voucher PIN number
                      </Label>
                      <Col sm={9}>
                        <div className="form-group">
                          <AvField
                            name="voucherPin"
                            className="form-control"
                            placeholder="Enter Voucher PIN"
                            type="text"
                            required
                          />
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row justify-content-end">
                      <Col sm={9}>
                        <Button type="submit" color="primary" className="w-md">
                          Load
                        </Button>
                      </Col>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VoucherRecharge.propTypes = {
  t: PropTypes.any,
}

export default VoucherRecharge
