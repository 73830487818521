export const rows = [
  {
    serial: "1",
    country: "Nigeria",
    standardRate: "$0.066",
    standardTen: "",
    AdvantageRate: "",
    AdvantageTen: "",
    PremiumRate: "",
    PremiumTen: "",
  },
  {
    name: "Garrett Winters",
    position: "Accountant",
    office: "Tokyo",
    age: "63",
    date: "2011/07/25",
    salary: "$170",
  },
  {
    name: "Ashton Cox",
    position: "Junior Technical Author",
    office: "San Francisco",
    age: "66",
    date: "2009/01/12",
    salary: "$86",
  },
  {
    name: "Cedric Kelly",
    position: "Senior Javascript Developer",
    office: "Edinburgh",
    age: "22",
    date: "2012/03/29",
    salary: "$433",
  },
]

export const columns = [
  {
    label: "S/N",
    field: "name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Country",
    field: "position",
    sort: "asc",
    width: 270,
  },
  {
    label: "Standard (rate/min)",
    field: "office",
    sort: "asc",
    width: 200,
  },
  {
    label: "$10 gives",
    field: "age",
    sort: "asc",
    width: 100,
  },
  {
    label: "Advantage (rate/min)",
    field: "date",
    sort: "asc",
    width: 150,
  },
  {
    label: "$10 gives",
    field: "salary",
    sort: "asc",
    width: 100,
  },
  {
    label: "Premium (rate/min)",
    field: "salary",
    sort: "asc",
    width: 100,
  },
  {
    label: "$10 gives",
    field: "salary",
    sort: "asc",
    width: 100,
  },
]
