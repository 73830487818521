import React, { Component } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"

class MonthlyEarning extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        {" "}
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Monthly Expenses</CardTitle>
            <Row>
              <Col sm="6">
                <p className="text-muted">This month</p>
                <h3>$252</h3>
                <p className="text-muted">
                  <span className="text-success mr-2">
                    {" "}
                    12% <i className="mdi mdi-arrow-down"></i>{" "}
                  </span>{" "}
                  From <br />
                  last Month
                </p>
                <h5 className="text-muted">March</h5>
                <h3>$282</h3>
                <p className="text-muted">
                  <span className="text-success mr-2">
                    {" "}
                    3% <i className="mdi mdi-arrow-up"></i>{" "}
                  </span>{" "}
                  From previous Month
                </p>
              </Col>

              <Col sm="6">
                <div className="mt-4 mt-sm-0">
                  <ApexRadial />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default MonthlyEarning
