import React, { Component } from "react"

import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

class LatestTranaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      transactions: [
        {
          id: "customCheck1",
          orderId: "#VF2540",
          billingName: "Neal Matthews",
          Date: "07 Oct, 2019",
          total: "$400",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "customCheck2",
          orderId: "#VF2541",
          billingName: "Jamal Burnett",
          Date: "07 Oct, 2019",
          total: "$380",
          badgeClass: "danger",
          paymentStatus: "Chargeback",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "customCheck3",
          orderId: "#VF2542",
          billingName: "Juan Mitchell",
          Date: "06 Oct, 2019",
          total: "$384",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "customCheck4",
          orderId: "#VF2543",
          billingName: "Barry Dick",
          Date: "05 Oct, 2019",
          total: "$412",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "customCheck5",
          orderId: "#VF2544",
          billingName: "Ronald Taylor",
          Date: "04 Oct, 2019",
          total: "$404",
          badgeClass: "warning",
          paymentStatus: "Refund",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "customCheck6",
          orderId: "#VF2545",
          billingName: "Jacob Hunter",
          Date: "04 Oct, 2019",
          total: "$392",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "customCheck7",
          orderId: "#VF2546",
          billingName: "Abdullahi Seidou",
          Date: "04 Oct, 2019",
          total: "$392",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "customCheck8",
          orderId: "#VF2547",
          billingName: "Tariq Adebayo",
          Date: "04 Oct, 2019",
          total: "$392",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "customCheck9",
          orderId: "#VF2548",
          billingName: "John James",
          Date: "04 Oct, 2019",
          total: "$392",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "customCheck10",
          orderId: "#VF2549",
          billingName: "Chukwuma Obi",
          Date: "04 Oct, 2019",
          total: "$392",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
      ],
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <EcommerceOrdersModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        />
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Top Customers</CardTitle>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: "20px" }}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp;
                        </label>
                      </div>
                    </th>
                    <th>Order ID</th>
                    <th>Billing Name</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Payment Status</th>
                    <th>Payment Method</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.transactions.map((transaction, key) => (
                    <tr key={"_tr_" + key}>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={transaction.id}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={transaction.id}
                          >
                            &nbsp;
                          </label>
                        </div>
                      </td>
                      <td>
                        <Link to="#" className="text-body font-weight-bold">
                          {" "}
                          {transaction.orderId}{" "}
                        </Link>{" "}
                      </td>
                      <td>{transaction.billingName}</td>
                      <td>{transaction.Date}</td>
                      <td>{transaction.total}</td>
                      <td>
                        <Badge
                          className={
                            "font-size-12 badge-soft-" + transaction.badgeClass
                          }
                          color={transaction.badgeClass}
                          pill
                        >
                          {transaction.paymentStatus}
                        </Badge>
                      </td>
                      <td>
                        <i
                          className={"fab " + transaction.methodIcon + " mr-1"}
                        ></i>{" "}
                        {transaction.paymentMethod}
                      </td>
                      <td>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light"
                          // onClick={this.togglemodal}
                          onClick={this.toggleModal}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default LatestTranaction
