import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import img7 from "assets/images/product/img-7.png"
import img4 from "assets/images/product/img-4.png"
import { AvField, AvForm } from "availity-reactstrap-validation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { assignLocalNumber, availableLocalNumbers } from "helpers/requests"

const AssignLocalNumber = props => {
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [options, setOptions] = useState([])
  const [localNumber, setLocalNumber] = useState(null)
  const handleLocalNumberSelect = event => {
    console.log(event.value)
    setLocalNumber(event)
  }

  let optionGroup = [
    {
      options,
    },
  ]
  useEffect(() => {
    console.log(availableNumbers)
    setOptions(
      availableNumbers.map(local => ({
        label: local.localNumber,
        value: local.localNumber,
      }))
    )
  }, [availableNumbers])

  const handleLocalNumberSubmit = () => {
    console.log("Help Me")
  }

  const { isOpen, toggle } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Add Authorized Country</ModalHeader>
        <Form onSubmit={handleLocalNumberSubmit}>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="formrow-email-Input">Authorized Country</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="formrow-email-Input"
                    placeholder={"Country"}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="formrow-email-Input">Purpose (optional)</Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="formrow-name-Input"
                    placeholder={"Purpose"}
                  />
                </FormGroup>
              </Col>{" "}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              className="btn btn-primary btn-block waves-effect waves-light"
              type="submit"
              color="primary"
            >
              Save
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

AssignLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignLocalNumber
