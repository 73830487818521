import React, { useState, useEffect } from "react"

//Verification code package
import AuthCode from "react-auth-code-input"

import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logo from "../../assets/images/logo.svg"

const TwostepVerification = () => {
  const [loading, setLoading] = useState(null)
  const [code, setCode] = useState("")

  const handleResend = async () => {
    const data = {
      mobile: localStorage.getItem("vomoz-mobile"),
      orgId: "939001",
      sendSms: "true",
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }

    const res = await verifyMobile(data)
    console.log(res)
    if (res.success === false) {
      setLoading(null)
    } else {
      localStorage.setItem("vomoz-code", res.data.code)

      setTimeout(() => {
        window.location.href = "/verify"
      }, 5000)
    }
  }

  const VerifyOTP = () => {
    console.log("OTP Verification")
    const savedCode = localStorage.getItem("vomoz-code")
    console.log(savedCode)

    if (code === savedCode) {
      localStorage.setItem("vomoz-verified", true)
      const existingCustomer = localStorage.getItem("isExistingCustomer")

      setTimeout(() => {
        if (!existingCustomer) window.location.href = "/join"
        else window.location.href = "/login"
      }, 3000)
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">Email Verification</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your email</h4>
                        <p className="mb-5">
                          Please enter the 4 digit code sent to{" "}
                          <span className="font-weight-semibold">
                            your mail
                          </span>
                        </p>

                        <Form>
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="verification">
                                <label
                                  htmlFor="digit1-input"
                                  className="sr-only"
                                >
                                  Dight 1
                                </label>
                                <AuthCode
                                  characters={4}
                                  className="form-control form-control-lg text-center"
                                  inputStyle={{
                                    width: "76px",
                                    height: "50px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    marginRight: "15px",
                                    border: "1px solid #ced4da",
                                    textTransform: "uppercase",
                                  }}
                                  onChange={value => setCode(value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>

                        <div className="mt-4">
                          <Link
                            to="dashboard"
                            className="btn btn-success w-md"
                            onClick={VerifyOTP}
                          >
                            Confirm
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Did't receive a code ?{" "}
                  <a href="#" className="font-weight-medium text-primary">
                    {" "}
                    {loading ? "Resending" : "Resend"}{" "}
                  </a>{" "}
                </p>
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TwostepVerification
