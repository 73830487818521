import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import validate from "validate.js"
// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

import { resetPassword } from "helpers/requests"

const schema = {
  newPassword: {
    presence: { allowEmpty: false, message: "is required" },

    length: {
      maximum: 256,
      minimum: 8,
    },
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
      minimum: 8,
    },
  },
}

const ResetPassword = ({ match }) => {
  const [loading, setLoading] = useState(null)

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: "VEZ18472128903",
      token: match.params.token,
      newPassword: formState.values.newPassword,
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }

    if (
      formState.isValid &&
      formState.values.newPassword === formState.values.confirmPassword
    ) {
      setLoading(true)
      const res = await resetPassword({ ...formState.values, ...data })
      setLoading(null)
      if (res.success === false) {
        setLoading(null)
      } else {
        setTimeout(() => {
          window.location.href = "/account"
        })
      }
    } else {
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }))
  }

  useEffect(() => {
    console.log(window.location.search)
  }, [])
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>
                        <span>
                          <p>Enter your details</p>
                        </span>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      action="dashboard"
                      name="password-reset-form"
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <FormGroup>
                        <Label for="useremail">New Password</Label>
                        <Input
                          label="New Password"
                          name="newPassword"
                          className="form-control"
                          id="useremail"
                          placeholder="Enter New Password"
                          helperText={
                            hasError("newPassword")
                              ? formState.errors.newPassword[0]
                              : null
                          }
                          error={hasError("newPassword")}
                          onChange={handleChange}
                          type="password"
                          value={formState.values.newPassword || ""}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="useremail">Confirm New Password</Label>
                        <Input
                          className="form-control"
                          id="useremail"
                          placeholder="Enter New Password again"
                          helperText={
                            hasError("confirmPassword")
                              ? formState.errors.confirmPassword[0]
                              : null
                          }
                          error={hasError("confirmPassword")}
                          onChange={handleChange}
                          type="password"
                          value={formState.values.confirmPassword || ""}
                        />
                      </FormGroup>

                      <FormGroup className="row mb-0">
                        <Col xs={12} className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? "Resetting..." : "Reset Password"}
                          </button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link
                    to="page-login"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
