import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import img7 from "assets/images/product/img-7.png"
import img4 from "assets/images/product/img-4.png"
import { AvField, AvForm } from "availity-reactstrap-validation"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { assignLocalNumber, availableLocalNumbers } from "helpers/requests"

const AssignLocalNumber = props => {
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [options, setOptions] = useState([])
  const [localNumber, setLocalNumber] = useState(null)
  const handleLocalNumberSelect = event => {
    console.log(event.value)
    setLocalNumber(event)
  }

  let optionGroup = [
    {
      options,
    },
  ]
  useEffect(() => {
    console.log(availableNumbers)
  }, [availableNumbers])

  const handleLocalNumberSubmit = async event => {
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: "VZF25758321589",
      id: "207",
      destination: "442082326578",
      nickname: "Elder John",
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }
    const Assigned = await assignLocalNumber(data)
  }

  const { isOpen, toggle } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Enter User's Details</ModalHeader>
        <Form onSubmit={handleLocalNumberSubmit}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Form>
                      <FormGroup className="row mb-4">
                        <Label
                          for="horizontal-firstname-Input"
                          className="col-sm-3 col-form-Label"
                        >
                          User's Email/Phone
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder="Enter the destination user's email"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          for="horizontal-email-Input"
                          className="col-sm-3 col-form-Label"
                        >
                          Amount
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="email"
                            className="form-control"
                            id="horizontal-email-Input"
                            placeholder="Enter Amount you want to transfer"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          for="horizontal-password-Input"
                          className="col-sm-3 col-form-Label"
                        >
                          Password
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="password"
                            className="form-control"
                            id="horizontal-password-Input"
                            placeholder="Enter your password"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Transfer
                            </Button>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}></Col>
            </Row>
          </ModalBody>
        </Form>
      </div>
    </Modal>
  )
}

AssignLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignLocalNumber
