import React, { Component } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import "./styles.scss"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import All from "./allCustomers"
import Pending from "./pending"
import Active from "./active"
import Disabled from "./disabled"
import { Link } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import classnames from "classnames"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import cryptoOrderColumn from "./cryptoOrderColumn"

class CryptoOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
      activeTab: "1",
    }
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    })
  }
  handleValidSubmit = () => {
    console.log("Hello World!")
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const { orders } = this.props
    const status = {
      completed: (
        <span className="badge badge-success font-size-10">Completed</span>
      ),
      pending: (
        <span className="badge badge-warning font-size-10">Pending</span>
      ),
      failed: <span className="badge badge-danger font-size-10">Failed</span>,
    }
    const data = {
      columns: cryptoOrderColumn,
      rows: map(orders, order => ({ ...order, status: status[order.status] })),
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Manage Users" breadcrumbItem="Customers" />

            <Row
              style={{
                display: "inline",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col lg="12" className="settings-panel">
                <Card>
                  <div className="settings-panel-card-body">
                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1")
                          }}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggleTab("2")
                          }}
                        >
                          Pending
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggleTab("3")
                          }}
                        >
                          Active
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggleTab("4")
                          }}
                        >
                          Disabled
                        </NavLink>
                      </NavItem>
                    </ul>

                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3"
                    >
                      <TabPane tabId="1" id="email">
                        <All />
                      </TabPane>
                      <TabPane tabId="2" id="security">
                        <Pending />
                      </TabPane>
                      <TabPane tabId="3" id="pin">
                        <Active />
                      </TabPane>
                      <TabPane tabId="4" id="details">
                        <Disabled />
                      </TabPane>
                    </TabContent>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CryptoOrders.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(CryptoOrders)
