import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import { listCustomers } from "helpers/requests"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EcommerceOrdersModal = props => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  // const [logs, setLogs] = useState([])
  const { isOpen, toggle, transaction = {} } = props
  const {
    userGlobalId,
    firstName,
    lastName,
    profilePicture,
    mobile,
    email,
    currency3Letters,
    accountBalance,
    signedUpDate,
    rewardPointsBalance,
    disabledReason
  } = transaction

  const localDate = new Date(signedUpDate).toDateString()
  const realDate = str => {
    let splitDate = str.split(" ")
    return (
      splitDate[0] +
      " " +
      splitDate[1] +
      " " +
      splitDate[2] +
      "," +
      " " +
      splitDate[3]
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        {/* {logs.map((transaction, index) => ( */}
        <div>
          <ModalHeader toggle={toggle}>Order Details</ModalHeader>
          <ModalBody>
            <img src={profilePicture} alt="" className="avatar-sm" />
            <p className="mb-4">
              Name:{" "}
              <span className="text-primary">
                {firstName}&nbsp;
                {lastName}
              </span>
            </p>
            <p className="mb-4">
              Phone Number: <span className="text-primary">{mobile}</span>
            </p>
            <p className="mb-4">
              Email Address: <span className="text-primary">{email}</span>
            </p>
            <p className="mb-4">
              Wallet Balance:{" "}
              <span className="text-primary">
                {currency3Letters + " " + accountBalance}
              </span>
            </p>
            <p className="mb-4">
              Reward-Point Balance:{" "}
              <span className="text-primary">{rewardPointsBalance}</span>
            </p>
            <p className="mb-4">
              Reason for Deactivation:{" "}
              <span className="text-primary">{disabledReason}</span>
            </p>
            <p className="mb-4">
              Registration Date:{" "}
              <span className="text-primary">{realDate(localDate)}</span>
            </p>
            <p className="mb-4">
              Registration Time:{" "}
              <span className="text-primary">
                {new Date(signedUpDate).toLocaleTimeString()}
              </span>
            </p>
            {/* <p className="mb-4">
                Application Time: <span className="text-primary">00:17:09</span>
              </p> */}
            <div className="table-responsive">
              <Button
                type="button"
                color="success"
                className="btn-square waves-effect waves-light mb-2 mr-2"
              >
                <i className="mdi mdi-pencil mr-1" />
                Edit
              </Button>
              <Button
                type="button"
                color="danger"
                className="btn-square waves-effect waves-light mb-2 mr-2"
              >
                <i className="mdi mdi-cancel mr-1" />
                Delete
              </Button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </div>
        {/* ))} */}
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
