import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Progress,
  Button,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import CreditCardInput from "react-credit-card-input"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const BuyCredit = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [activeTabProgress, setActiveTabProgress] = useState(1)
  const [progressValue, setProgressValue] = useState(25)
  const [cardDetails, setCardDetails] = useState({
    number: "",
    expiring: "",
    cvv: "",
    type: "",
  })

  const handleChange = (event, name) => {
    console.log(cardDetails)

    console.log(name)
    setCardDetails({ ...cardDetails, [name]: event.target.value })
    console.log(event.target.value)

    console.log(cardDetails.number)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab)
      }
    }
  }

  const toggleTabProgress = tab => {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTabProgress(tab)

        if (tab === 1) {
          setActiveTabProgress(33)
        }
        if (tab === 2) {
          setActiveTabProgress(67)
        }
        if (tab === 3) {
          setActiveTabProgress(100)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Credit"} breadcrumbItem={"Buy Credit"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="card-title mb-4">Credit Purchase</h4>

                    <Button type="button" color="primary" className="m-2">
                      Existing Card?
                    </Button>
                  </div>

                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 1,
                          })}
                          onClick={() => {
                            toggleTab(1)
                          }}
                        >
                          <span className="step-number mr-2">01</span>
                          Card Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 2,
                          })}
                          onClick={() => {
                            toggleTab(2)
                          }}
                        >
                          <span className="step-number mr-2">02</span>
                          <span>Billing Address</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 3,
                          })}
                          onClick={() => {
                            toggleTab(3)
                          }}
                        >
                          <span className="step-number mr-2">03</span>
                          Confirm Detail
                        </NavLink>
                      </NavItem>
                    </ul>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="firstName">First Name</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="lastName">Last Name</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="otherName(s)">
                                    Other Name(s)
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="otherName(s)"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="amount">Amount</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="amount"
                                    placeholder="$"
                                    type="number"
                                    prefix="$"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label>Credit Card Type</Label>
                                  <select className="custom-select">
                                    <option defaultValue>
                                      Select Card Type
                                    </option>
                                    <option value="AE">American Express</option>
                                    <option value="VI">Visa</option>
                                    <option value="MC">MasterCard</option>
                                    <option value="DI">Discover</option>
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="basicpill-cardno-input12">
                                    Credit Card Number
                                  </Label>
                                  <CreditCardInput
                                    cardNumberInputProps={{
                                      value: cardDetails.number,
                                      onChange: event =>
                                        handleChange(event, "number"),
                                    }}
                                    cardExpiryInputProps={{
                                      value: cardDetails.expiring,
                                      onChange: event =>
                                        handleChange(event, "expiring"),
                                    }}
                                    cardCVCInputProps={{
                                      value: cardDetails.ccv,
                                      onChange: event =>
                                        handleChange(event, "cvv"),
                                    }}
                                    fieldClassName="input"
                                  />
                                </FormGroup>
                              </Col>

                              {/* <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-card-verification-input">
                                    Security Code (CVV)
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-card-verification-input"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-expiration-input13">
                                    Expiration Date
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-expiration-input13"
                                    type="date"
                                  />
                                </FormGroup>
                              </Col> */}
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-namecard-input11">
                                    Address
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-namecard-input11"
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-namecard-input11">
                                    Country
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-namecard-input11"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-namecard-input11">
                                    State/Province
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-namecard-input11"
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-namecard-input11">
                                    City
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-namecard-input11"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-namecard-input11">
                                    ZipCode
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-namecard-input11"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Credit Purchase</h5>
                              </div>
                            </div>
                          </Col>
                        </div>

                        <Row>
                          <Col md={6}>
                            <div>
                              <h6>Amount: $100</h6>
                              <h6>Method: Credit Card</h6>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1)
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={activeTab === 3 ? "next disabled" : "next"}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab + 1)
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BuyCredit.propTypes = {
  t: PropTypes.any,
}

export default BuyCredit
