import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const FollowUs = () => {
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />
          <div className="checkout-tabs">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <Nav
                    style={{ display: "flex", justifyContent: "space-around" }}
                    className="flex-column"
                    pills
                  >
                    <NavItem>
                      <NavLink
                        target="_blank"
                        rel="noopener"
                        href="https://www.facebook.com"
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        <i className="bx bxl-facebook-square d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Facebook</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        target="_blank"
                        rel="noopener"
                        href="https://www.twitter.com"
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        <i className="bx bxl-twitter d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Twitter</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        target="_blank"
                        rel="noopener"
                        href="https://www.youtube.com"
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        <i className="bx bxl-youtube d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Youtube</p>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        target="_blank"
                        rel="noopener"
                        href="https://www.instagram.com"
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        <i className="bx bxl-instagram d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Instagram</p>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

FollowUs.propTypes = {
  t: PropTypes.any,
}

export default FollowUs
