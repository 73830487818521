import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Collapse,
  CardBody,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const AccessNumbers = () => {
  const [open, setOpen] = useState(0)

  const handleAccordion = (event, accord) => {
    if (open !== accord) setOpen(accord)
    else setOpen(0)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Numbers"} breadcrumbItem={"Access Numbers"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <CardTitle>Access Numbers</CardTitle>
                  <CardSubtitle className="mb-3">
                    You can use any of the listed Access Numbers even if your
                    city is not listed!
                  </CardSubtitle>

                  <div id="accordion">
                    <Card className="mb-1">
                      <CardHeader className="p-3" id="headingOne">
                        <h6 className="m-0 font-14">
                          <span
                            onClick={event => handleAccordion(event, "1")}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            United States
                          </span>
                        </h6>
                      </CardHeader>

                      <Collapse isOpen={open === "1"}>
                        <Card>
                          <CardBody>
                            <ul>
                              <li>California: 619-342-3935 , 323-213-2011</li>
                              <li> Delaware: 302-258-2656</li>

                              <li> Florida: 786-522-9519</li>

                              <li> Georgia: 678-248-2228 , 404-935-4217</li>

                              <li> Illinois: 773-932-1642</li>

                              <li>
                                {" "}
                                Maryland: 443-365-2892 , 410-202-2479 ,
                                301-685-1752 , 240-823-3005
                              </li>

                              <li> Massachusetts: 413-512-2155</li>

                              <li> Minnesota: 612-225-1976</li>

                              <li> New Jersey: 201-839-0516 , 732-720-8098</li>

                              <li> New York: 646-862-5179 , 718-989-9096</li>

                              <li> North Carolina: 704-257-1787</li>

                              <li> Ohio: 614-600-3006</li>

                              <li>
                                {" "}
                                Pennsylvania: 215-525-0768 , 412-542-1513
                              </li>

                              <li> Rhode Island: 401-488-2244</li>

                              <li> Tennesse: 615-349-9384</li>

                              <li>
                                {" "}
                                Texas: 214-453-1340 , 281-971-3556 ,
                                281-971-1781
                              </li>

                              <li> Virginia: 703-543-5573</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Card>
                    <Card className="mb-1">
                      <CardHeader className="p-3" id="headingTwo">
                        <h6 className="m-0 font-14">
                          <span
                            onClick={event => handleAccordion(event, "2")}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            Canada
                          </span>
                        </h6>
                      </CardHeader>
                      <Collapse isOpen={open === "2"}>
                        <Card>
                          <CardBody>
                            <ul>
                              <li>Canada</li>
                              <li> 289-639-2114</li>
                              <li> 403-775-9686</li>
                              <li> 416-477-2280</li>
                              <li> 587-887-1220</li>
                              <li> 604-654-2560</li>
                              <li> 613-686-6409</li>
                              <li> 647-247-1299</li>
                              <li> 705-881-1526</li>
                              <li> 778-373-8444</li>
                              <li> 780-851-7009</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Collapse>{" "}
                    </Card>
                    <Card className="mb-0">
                      <CardHeader className="p-3" id="headingThree">
                        <h6 className="m-0 font-14">
                          <span
                            onClick={event => handleAccordion(event, "3")}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            United Kingdom
                          </span>
                        </h6>
                      </CardHeader>
                      <Collapse isOpen={open === "3"}>
                        <Card>
                          <CardBody>
                            <ul>
                              <li>United Kingdom: 0203-318-2083</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Collapse>{" "}
                    </Card>
                    <Card className="mb-0">
                      <CardHeader className="p-3" id="headingThree">
                        <h6 className="m-0 font-14">
                          <span
                            onClick={event => handleAccordion(event, "4")}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            Ireland
                          </span>
                        </h6>
                      </CardHeader>
                      <Collapse isOpen={open === "4"}>
                        <Card>
                          <CardBody>
                            <ul>
                              <li> Ireland: 01-653-0972</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AccessNumbers.propTypes = {
  t: PropTypes.any,
}

export default AccessNumbers
