export const team = [
  {
    id: 1,
    img: "Null",
    name: "Nigeria",
    skills: [
      { id: 1, name: "Work" },
      { id: 2, name: "Hotel" },
    ],
  },
  {
    id: 2,
    img: "Null",
    name: "Brazil",
    skills: [{ id: 1, name: "Sportsman" }],
  },
  {
    id: 3,
    img: "Null",
    name: "South Africa",
    skills: [{ id: 1, name: "Home" }],
  },
  {
    id: 4,
    img: "Null",
    name: "Canada",
    skills: [
      { id: 1, name: "Estate Manager" },
      { id: 2, name: "Resort" },
    ],
  },
  {
    id: 5,
    img: "Null",
    name: "China",
    skills: [{ id: 1, name: "Doctor" }],
  },
]
