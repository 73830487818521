import React, { Component } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap"

import { Link } from "react-router-dom"
import EcommerceOrdersModal from "../MREcommerce/EcommerceOrders/EcommerceOrdersModal"

class ManageRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      transactions: [
        {
          id: "1",
          billingName: "Neal Matthews",
          phone: "+123456789023",
          email: "neal.matthews@gmail.com",
          Date: "Oct 07, 2019",
          time: "04:32:10",
          badgeClass: "success",
          paymentStatus: "Active",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "2",
          billingName: "Jamal Burnett",
          phone: "+349755330021",
          email: "jamal.burnett@gmail.com",
          Date: "Jan 01, 2020",
          time: "03:24:08",
          badgeClass: "danger",
          paymentStatus: "Inactive",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "3",
          billingName: "Kyle Adams",
          phone: "+2346755330021",
          email: "kyleadams@hotmail.com",
          Date: "Jan 07, 2020",
          time: "19:04:08",
          badgeClass: "danger",
          paymentStatus: "Inactive",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "4",
          billingName: "Audrey Williams",
          phone: "+4490234787744",
          email: "audrey_williams@gmail.com",
          Date: "Jan 22, 2020",
          time: "03:24:08",
          badgeClass: "warning",
          paymentStatus: "Pending",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "5",
          billingName: "Ronald Taylor",
          phone: "!754309338833",
          email: "ronald_taylor@yahoo.com",
          Date: "Jan 15, 2020",
          time: "05:46:29",
          badgeClass: "warning",
          paymentStatus: "Pending",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "6",
          billingName: "Jacob Hunter",
          phone: "346230987765",
          email: "jacob.hunter@gmail.com",
          Date: "Oct 04, 2019",
          time: "13:34:09",
          badgeClass: "success",
          paymentStatus: "Active",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "7",
          billingName: "Abdullahi Seidou",
          phone: "973356687484",
          email: "abdullahiseidou@yahoo.com",
          Date: "Oct 06, 2019",
          time: "19:22:00",
          badgeClass: "success",
          paymentStatus: "Active",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "8",
          billingName: "Tariq Adebayo",
          phone: "4452223456772",
          email: "tariq_adebayo@gmail.com",
          Date: "Feb 16, 2021",
          time: "20:13:43",
          badgeClass: "danger",
          paymentStatus: "Inactive",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "9",
          billingName: "John James",
          phone: "245680011123",
          email: "johnjames@gmail.com",
          Date: "Dec 23, 2020",
          time: "09:23:45",
          badgeClass: "warning",
          paymentStatus: "Pending",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "10",
          billingName: "Chukwuma Obi",
          phone: "+2348908746532",
          email: "chukwuma.obi12@gmail.com",
          Date: "May 03, 2021",
          time: "08:28:11",
          badgeClass: "success",
          paymentStatus: "Active",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
      ],
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <EcommerceOrdersModal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Top Customers</CardTitle>
                  <Row>
                    <Col>
                      <br />
                      <h4>Manage Registrations</h4>
                      <br />
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-right">
                        <br />
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                        >
                          <i className="mdi mdi-plus mr-1" />
                          Add New Customer
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Applicant Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Application Date</th>
                          <th>Application Time</th>
                          <th>Application Status</th>
                          <th>Review Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.transactions.map((transaction, key) => (
                          <tr key={"_tr_" + key}>
                            <td>
                              <p>{transaction.id}</p>
                            </td>
                            <td>{transaction.billingName}</td>
                            <td>{transaction.phone}</td>
                            <td>{transaction.email}</td>
                            <td>{transaction.Date}</td>
                            <td>{transaction.time}</td>
                            <td>
                              <Badge
                                className={
                                  "font-size-12 badge-soft-" +
                                  transaction.badgeClass
                                }
                                color={transaction.badgeClass}
                                pill
                              >
                                {transaction.paymentStatus}
                              </Badge>
                            </td>
                            <td>
                              <Button
                                type="button"
                                color="primary"
                                size="sm"
                                className="btn-rounded waves-effect waves-light"
                                // onClick={this.togglemodal}
                                onClick={this.toggleModal}
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

export default ManageRegistration
