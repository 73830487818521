import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.jpg"
import img4 from "../../../assets/images/product/img-4.png"

const EcommerceOrdersModal = props => {
  const { isOpen, toggle } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Customer Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Vomoz ID: <span className="text-primary">#VF2540</span>
          </p>
          <p className="mb-4">
            Customer Name: <span className="text-primary">Neal Matthews</span>
          </p>
          <p className="mb-4">
            <img src={img7} alt="" className="avatar-sm" />
          </p>
          <p className="mb-4">
            Phone Number: <span className="text-primary">+123456789012</span>
          </p>
          <p className="mb-4">
            Email Address:{" "}
            <span className="text-primary">neal.matthews@gmail.com</span>
          </p>
          <p className="mb-4">
            Location:{" "}
            <span className="text-primary">Maryland, United States</span>
          </p>
          <p className="mb-4">
            Wallet Balance: <span className="text-primary">$234.06</span>
          </p>
          <p className="mb-4">
            Joining Date: <span className="text-primary">Oct 31, 2020</span>
          </p>
          <p className="mb-4">
            Joining Time: <span className="text-primary">00:17:09</span>
          </p>
          <p className="mb-4">
            Magico Numbers:{" "}
            <span className="text-primary">
              <ol>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
              </ol>
            </span>
          </p>
          <p className="mb-4">
            Pinless Numbers:{" "}
            <span className="text-primary">
              <ol>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
                <li>+123456789</li>
              </ol>
            </span>
          </p>
          <p className="mb-4">
            Partnership Status: <span className="text-primary">Partner</span>
          </p>

          <div className="table-responsive">
            <Table className="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action Details</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM001</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Oct 31, 2020
                      </h5>
                      <p className="text-muted mb-0">00:17:09</p>
                    </div>
                  </td>
                  <td>Applied to join the platform</td>
                  <td>$0.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM002</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Nov 01, 2020
                      </h5>
                      <p className="text-muted mb-0">00:17:09</p>
                    </div>
                  </td>
                  <td>Registration approved</td>
                  <td>$0.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM003</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Nov 01, 2020
                      </h5>
                      <p className="text-muted mb-0">00:17:57</p>
                    </div>
                  </td>
                  <td>Bought credit</td>
                  <td>$234.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM004</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Nov 02, 2020
                      </h5>
                      <p className="text-muted mb-0">06:17:09</p>
                    </div>
                  </td>
                  <td>Transfered credit to +1234567892</td>
                  <td>$34.67</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM005</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Nov 21, 2020
                      </h5>
                      <p className="text-muted mb-0">13:17:09</p>
                    </div>
                  </td>
                  <td>Called +12345678963 for 23 seconds</td>
                  <td>$56.09</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM006</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Nov 30, 2020
                      </h5>
                      <p className="text-muted mb-0">07:19:09</p>
                    </div>
                  </td>
                  <td>Called +156345678963 for 48 minutes</td>
                  <td>$6.78</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM007</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Nov 30, 2020
                      </h5>
                      <p className="text-muted mb-0">09:54:00</p>
                    </div>
                  </td>
                  <td>Contacted the Admin</td>
                  <td>$0.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM008</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Dec 21, 2020
                      </h5>
                      <p className="text-muted mb-0">00:17:09</p>
                    </div>
                  </td>
                  <td>Added +16234345677 as Magico Numbers</td>
                  <td>$0.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM009</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Dec 21, 2020
                      </h5>
                      <p className="text-muted mb-0">00:57:21</p>
                    </div>
                  </td>
                  <td>Added +23481209622 as Pinless Number</td>
                  <td>$0.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM0010</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Dec 22, 2020
                      </h5>
                      <p className="text-muted mb-0">10:17:39</p>
                    </div>
                  </td>
                  <td>Called +12500933333 for 1 hours 45 minutes 60 seconds</td>
                  <td>$90.30</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM0011</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Dec 24, 2020
                      </h5>
                      <p className="text-muted mb-0">00:17:09</p>
                    </div>
                  </td>
                  <td>Added +23481209622 as Magico Number</td>
                  <td>$0.00</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <h5 className="text-truncate font-size-14">NM0012</h5>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Dec 31, 2020
                      </h5>
                      <p className="text-muted mb-0">00:17:09</p>
                    </div>
                  </td>
                  <td>Added +23481209622 as Pinless Number</td>
                  <td>$0.00</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
