import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  match,
  ...rest
}) => {
  console.log(match)
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("userData")) {
          return (
            // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            <Redirect to="/login" />
          )
        }
        if (!isAuthProtected && localStorage.getItem("userData")) {
          return (
            // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            <Redirect to="/dashboard" />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default AppRoute
