import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css"
import { ordersData } from "./data"

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"
// import { orders } from "./data"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import transferColumn from "./components/transferColumns"
import AddTransfer from "./components/add"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const TransferCredit = () => {
  const [orders, setOrders] = useState(ordersData)
  const [addModal, setAddModal] = useState(false)

  const toggleEditModal = order => {
    setEditModalInfo(order)
    setEditModal(!editModal)
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }

  const status = {
    completed: (
      <span className="badge badge-success font-size-10">Completed</span>
    ),
    pending: <span className="badge badge-warning font-size-10">Pending</span>,
    failed: <span className="badge badge-danger font-size-10">Failed</span>,
  }
  const data = {
    columns: transferColumn,
    rows: map(orders, order => ({
      ...order,
    })),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <AddTransfer isOpen={addModal} toggle={toggleAddModal} />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Credit"} breadcrumbItem={"Transfer Credit"} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="card-title mb-3">
                      Transfer Credit to Friends and Family
                    </h4>

                    <Button
                      type="button"
                      color="primary"
                      className="w-md"
                      onClick={toggleAddModal}
                    >
                      Transfer +
                    </Button>
                  </div>

                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    className="mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TransferCredit.propTypes = {
  t: PropTypes.any,
}

export default TransferCredit
