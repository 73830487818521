// import images
import profileImg from "assets/images/profile-img.svg"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

const PIN = () => {
  const handleValidSubmit = () => {
    console.log("Hello World!")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Security"} breadcrumbItem={"PIN"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Update PIN</h5>
                        {/* <p>Change Security PIN</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      <div className="form-group">
                        <AvField
                          name="currentPIN"
                          label="Current PIN"
                          className="form-control"
                          placeholder="Current PIN"
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="newPIN"
                          label="New PIN"
                          type="email"
                          required
                          placeholder="New PIN"
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter your Password"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By submitting you agree to the Vomoz{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PIN.propTypes = {
  t: PropTypes.any,
}

export default PIN
