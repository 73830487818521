import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  CardSubtitle,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"



const Contact = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Contact"} breadcrumbItem={"Support"} />
          <Row className={"justify-content-center"}>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    We would love to hear from you!
                  </CardTitle>
                  <CardSubtitle></CardSubtitle>
                  <br />
                  <AvForm className={"justify-content-center"}>
                    <Col>
                      <div className="form-group">
                        <AvField
                          name="voucherPin"
                          className="form-control"
                          placeholder="Enter Full Name"
                          type="text"
                          required
                          label={"Name"}
                        />
                      </div>
                    </Col>

                    <Col>
                      <div className="form-group">
                        <AvField
                          name="voucherPin"
                          className="form-control"
                          placeholder="Enter contact email"
                          type="text"
                          label={"Email"}
                          required
                        />
                      </div>
                    </Col>

                    <Col>
                      <div className="form-group">
                        <AvField
                          name="voucherPin"
                          className="form-control"
                          placeholder="Enter the subject"
                          type="text"
                          required
                          label={"Subject"}
                        />
                      </div>
                    </Col>

                    <Col>
                      <div className="form-group">
                        <AvField
                          name="voucherPin"
                          className="form-control"
                          placeholder="Enter your message"
                          type="textarea"
                          rows={6}
                          required
                          label={"Message"}
                        />
                      </div>
                    </Col>

                    <FormGroup className="row justify-content-end">
                      <Col sm={9}>
                        <Button type="submit" color="primary" className="w-md">
                          Submit
                        </Button>
                      </Col>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Contact.propTypes = {
  t: PropTypes.any,
}

export default Contact
