import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormGroup,
  Label,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import {
  apiError,
  loginUser,
  socialLogin,
  userAccountParams,
} from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { SignIn } from "helpers/requests"

const Login = props => {
  const [loading, setLoading] = useState(null)
  const [user, setUser] = useState({ email: "", password: "" })
  const [passwordType, setPasswordType] = useState("password")

  //   const handleValidSubmit = (event, values) => {
  //     props.loginUser(values, props.history)
  //   }

  useEffect(() => {
    props.apiError("")
  }, [])

  const handleInputchange = event => {
    console.log(event.target.value)
    setUser({ ...user, [event.target.name]: event.target.value })
    console.log(user)
  }
  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const data = {
      orgId: "939001",
      referenceId: "3RB3t99390ZI322B18712",
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }

    if (user.email && user.password) {
      const res = await SignIn({ ...data, ...user })
      if (res === undefined) alert("Please Check your internet connection")
      setLoading(null)
      if (res?.success === false) {
        console.log(res)
      } else if (res?.success === true) {
        setTimeout(() => {
          window.location.href = "/dashboard"
        }, 500)
      }
    }
    setUser({ email: "", password: "" })
  }

  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <a href="http://localhost:3000" className="text-dark">
          <i className="bx bx-home h2" />
        </a>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleSubmit}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          value={user.email}
                          className="form-control"
                          placeholder="Enter email"
                          onChange={handleInputchange}
                          type="email"
                          required
                        />
                      </div>

                      <FormGroup>
                        <Label>Password</Label>
                        <InputGroup>
                          <input
                            name="password"
                            value={user.password}
                            onChange={handleInputchange}
                            type={passwordType}
                            required
                            placeholder="Enter Password"
                            className="form-control"
                          />
                          <div
                            className="input-group-append"
                            style={{ cursor: "pointer" }}
                            onClick={handlePasswordType}
                          >
                            <span className="input-group-text">
                              {passwordType === "text" ? (
                                <i class="fa fa-low-vision"></i>
                              ) : (
                                <i class="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </span>
                          </div>
                        </InputGroup>
                      </FormGroup>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {loading ? "Logging" : "Log In"}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              render={renderProps => (
                                <Link
                                  to={""}
                                  className="social-list-item bg-primary text-white border-primary"
                                  // onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to={""}
                                  className="social-list-item bg-danger text-white border-danger"
                                  // onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={""}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{" "}
                  <Link
                    to="mobile-verification"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
