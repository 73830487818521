import Axios, { config } from "./axios"

export const sendEmail = async emailData => {
  try {
    const response = await Axios.post("send-email/", { ...emailData }, config)
    const data = response.data
    localStorage.setItem("sendEmail", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const joinNewsletter = async newsLetterData => {
  try {
    const response = await Axios.post(
      "join-newsletter/",
      { ...newsLetterData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const unSubscribe = async unSubscribeData => {
  try {
    const response = await Axios.post(
      "unsubscribe/",
      { ...unSubscribeData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const localNumbersAssigned = async localNumbersAssignedData => {
  try {
    const response = await Axios.post(
      "local-numbers-assigned/",
      { ...localNumbersAssignedData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const availableLocalNumbers = async availableLocalNumbersData => {
  try {
    const response = await Axios.post(
      "available-local-numbers/",
      { ...availableLocalNumbersData },
      config
    )
    const data = response.data
    localStorage.setItem("availableLocalNumbers", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const assignLocalNumber = async assignLocalNumberData => {
  try {
    const response = await Axios.post(
      "assign-local-number/",
      { ...assignLocalNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const editLocalNumber = async editLocalNumberData => {
  try {
    const response = await Axios.post(
      "edit-local-number/",
      { ...editLocalNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteLocalNumber = async deleteLocalNumberData => {
  try {
    const response = await Axios.post(
      "delete-local-number/",
      { ...deleteLocalNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const callerIdsVerified = async callerIdsVerifiedData => {
  try {
    const response = await Axios.post(
      "callerids-verified/",
      { ...callerIdsVerifiedData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const callLogs = async callsLogData => {
  try {
    const response = await Axios.post("calls-log/", { ...callsLogData }, config)
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const changePassword = async passwordData => {
  try {
    const response = await Axios.post(
      "change-password/",
      { ...passwordData },
      config
    )
    const data = response.data
    localStorage.setItem("passwordData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const changeEmail = async emailData => {
  try {
    const response = await Axios.post("change-email/", { ...emailData }, config)
    const data = response.data
    localStorage.setItem("emailData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const updateProfile = async profileData => {
  try {
    const response = await Axios.post(
      "update-profile/",
      { ...profileData },
      config
    )
    const data = response.data
    localStorage.setItem("profileData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const accountParameters = async accountParametersData => {
  try {
    const response = await Axios.post(
      "account-parameters/",
      { ...accountParametersData },
      config
    )
    const data = response.data
    console.log(response)
    localStorage.setItem("accountParametersData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    console.log(error)
  }
}

export const searchRates = async searchRatesData => {
  try {
    const response = await Axios.post(
      "search-rates/",
      { ...searchRatesData },
      config
    )
    const data = response.data
    localStorage.setItem("searchRatesData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const systemSettings = async systemSettingsData => {
  try {
    const response = await Axios.post(
      "system-settings/",
      { ...systemSettingsData },
      config
    )
    const data = response.data
    localStorage.setItem("systemSettingsData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const verifyMobile = async verifyMobileData => {
  try {
    const response = await Axios.post(
      "verify-mobile-number/",
      { ...verifyMobileData },
      config
    )
    const data = response.data
    localStorage.setItem("verifyMobileData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const SignUp = async signUpData => {
  try {
    const response = await Axios.post("signup/", { ...signUpData }, config)
    const data = response.data

    localStorage.setItem("userData", JSON.stringify(data.responseData))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const SignIn = async signInData => {
  try {
    const response = await Axios.post("login/", { ...signInData }, config)

    const data = response.data
    const responseCode = data.responseCode
    if (responseCode === "00") {
      localStorage.setItem("userData", JSON.stringify(data.responseData))
      console.log(data.responseData)
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    } else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const forgotPassword = async forgotPasswordData => {
  try {
    const response = await Axios.post(
      "forgotpassword/",
      { ...forgotPasswordData },
      config
    )
    const data = response.data
    localStorage.setItem("forgotPasswordData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const resetPassword = async resetPasswordData => {
  try {
    const response = await Axios.post(
      "resetpassword/",
      { ...resetPasswordData },
      config
    )
    const data = response.data
    localStorage.setItem("resetPasswordData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const addCallerId = async addCallerIdData => {
  try {
    const response = await Axios.post(
      "add-caller-id/",
      { ...addCallerIdData },
      config
    )
    const data = response.data
    localStorage.setItem("addCallerIdData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const addCallerIdProcess = async addCallerIdProcessData => {
  try {
    const response = await Axios.post(
      "add-caller-id-process/",
      { ...addCallerIdProcessData },
      config
    )
    const data = response.data
    localStorage.setItem("addCallerIdProcessData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteCallerId = async deleteCallerIdData => {
  try {
    const response = await Axios.post(
      "delete-caller-id/",
      { ...deleteCallerIdData },
      config
    )
    const data = response.data
    localStorage.setItem("deleteCallerIdData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const transferCredit = async transferCreditData => {
  try {
    const response = await Axios.post(
      "transfer-credit/",
      { ...transferCreditData },
      config
    )
    const data = response.data
    localStorage.setItem("transferCreditData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const existingBeneficiariesTransferCredit = async existingBeneficiariesTransferCreditData => {
  try {
    const response = await Axios.post(
      "existing-beneficiaries-transfer-credit/",
      { ...existingBeneficiariesTransferCreditData },
      config
    )
    const data = response.data
    localStorage.setItem(
      "existingBeneficiariesTransferCreditData",
      JSON.stringify(data)
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const transferCreditHistory = async transferCreditHistoryData => {
  try {
    const response = await Axios.post(
      "transfer-credit-history/",
      { ...transferCreditHistoryData },
      config
    )
    const data = response.data
    localStorage.setItem("transferCreditHistoryData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const topUpWithVoucher = async topUpWithVoucherData => {
  try {
    const response = await Axios.post(
      "top-up-with-voucher/",
      { ...topUpWithVoucherData },
      config
    )
    const data = response.data
    localStorage.setItem("topUpWithVoucherData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const topUpVoucherHistory = async topUpVoucherHistoryData => {
  try {
    const response = await Axios.post(
      "vouchers-history/",
      { ...topUpVoucherHistoryData },
      config
    )
    const data = response.data
    localStorage.setItem("topUpVoucherHistoryData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const showPin = async showPinData => {
  try {
    const response = await Axios.post("show-pin/", { ...showPinData }, config)
    const data = response.data
    localStorage.setItem("showPinData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const changePin = async changePinData => {
  try {
    const response = await Axios.post(
      "change-pin/",
      { ...changePinData },
      config
    )
    const data = response.data
    localStorage.setItem("changePinData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const listCustomers = async listCustomersData => {
  try {
    const response = await Axios.post(
      "list-customers/",
      { ...listCustomersData },
      config
    )
    console.log(response)
    const data = response.data
    localStorage.setItem("listCustomersData", JSON.stringify(data))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
