import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import {
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable, MDBBadge } from "mdbreact"
import SweetAlert from "react-bootstrap-sweetalert"
import Breadcrumbs from "components/Common/Breadcrumb"
import { listCustomers } from "helpers/requests"
import { Link } from "react-router-dom"
import EcommerceOrdersModal from "../MREcommerce/EcommerceOrders/EcommerceOrdersModal"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { map } from "lodash"
import { Fragment } from "react"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const allCustomers = () => {
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])
  const [transaction, setTransaction] = useState({})
  const openModal = transaction => () => {
    setTransaction(transaction)
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
  }

  const handleChange = date => {
    setStartDate(date)
    console.log(date.toLocaleDateString("en-CA"))
  }
  const handleChange2 = date => {
    setEndDate(date)
  }

  const getListCustomerLogs = async () => {
    setLoading(true)
    console.log(endDate?.toLocaleDateString("en-CA"))
    console.log(startDate?.toLocaleDateString("en-CA"))
    const data = {
      orgId: "939001",
      status: "",
      startDate: startDate?.toLocaleDateString("en-CA") || null,
      endDate: endDate?.toLocaleDateString("en-CA") || null,
      numberOfRecords: "",
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }

    const log = await listCustomers(data)
    console.log(log)
    const ListCustomerLog = log?.data?.returnedRecords
    console.log(ListCustomerLog)
    if (ListCustomerLog) setLogs(ListCustomerLog)
    setLoading(false)
  }

  useEffect(() => {
    getListCustomerLogs()
  }, [endDate])

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "First Name",
        field: "firstName",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "First Name",
        },
        sort: "asc",
      },
      {
        label: "Last Name",
        field: "lastName",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "First Name",
        },
        sort: "asc",
      },
      {
        label: "Phone",
        field: "mobile",
        width: 150,
        sort: "asc",
      },
      {
        label: "Email",
        field: "email",
        width: 500,
        sort: "asc",
      },
      {
        label: "Balance ($)",
        field: "accountBalance",
        width: 100,
        sort: "asc",
      },
    ],
  })

  useEffect(() => {
    if (logs.length > 0) {
      const badgesData = {
        columns: [
          {
            label: "#",
            field: "id",
          },
          ...datatable.columns,
          {
            label: "Status",
            field: "status",
            width: 200,
          },
          {
            label: "More",
            field: "more",
            width: 200,
          },
        ],
        rows: [
          ...logs.map((row, order) => ({
            ...row,
            id: <>{order + 1}</>,

            status: (
              <Badge
                color={
                  row.status === "Active"
                    ? "success"
                    : row.status === "Pending"
                    ? "warning"
                    : "danger"
                }
                pill
                width="20px"
              >
                {row.status}
              </Badge>
            ),
            email:
              row?.email?.length > 20
                ? row?.email?.slice(0, 20) + "..."
                : row?.email,
            more: (
              <Button
                type="button"
                color="primary"
                size="sm"
                className="btn-square waves-effect waves-light"
                // onClick={this.togglemodal}
                onClick={openModal(row)}
              >
                View Details
              </Button>
            ),
          })),
        ],
      }
      setDatatable(badgesData)
    }
  }, [logs])

  return (
    <React.Fragment>
      <EcommerceOrdersModal
        isOpen={modal}
        transaction={transaction}
        toggle={closeModal}
      />

      <Card>
        <CardBody className="all-customer-card-body">
          <div
            className="mb-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <Row>
                <div className="col-xl col-sm-6 all-customers-start-date">
                  <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    className="form-control"
                    placeholderText="Start date"
                  />
                </div>
                <div className="col-xl col-sm-6">
                  <DatePicker
                    selected={endDate}
                    onChange={handleChange2}
                    className="form-control"
                    placeholderText="End date"
                  />
                </div>
              </Row>

              <Button
                type="button"
                color="success"
                className="btn-square waves-effect waves-light all-customers-add-button"
              >
                <i className="mdi mdi-plus mr-1" />
                <span>Add New Customer</span>
              </Button>
            </div>
          </div>
          <div style={{ width: "100%", overflow: "auto" }}>
            <MDBDataTable
              hover
              entriesOptions={[100, 200, 500, 1000]}
              entries={100}
              pagesAmount={4}
              data={datatable}
              striped
              style={{
                marginTop: "10px",
              }}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

// allCustomers.propTypes = {
//   t: PropTypes.any,
// }

export default allCustomers
