import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import img7 from "assets/images/product/img-7.png"
import img4 from "assets/images/product/img-4.png"
import { AvField, AvForm } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { editLocalNumber } from "helpers/requests"
import { orders } from "common/data"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EditLocalNumber = ({ editInfo, isOpen, toggle }) => {
  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState({})
  const [salert, setSalert] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  useEffect(() => {
    setInfo(editInfo)
    console.log(editInfo)
  }, [editInfo])

  const handleChange = event => {
    setInfo({ ...info, [event.target.name]: event.target.value })
  }

  const handleLocalNumberEdit = async event => {
    setLoading(true)
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }
    const Editted = await editLocalNumber({ ...data, ...info })
    console.log(Editted)
    setLoading(null)
    toggle()

    if (Editted?.success) {
      toggle()
      setSalert({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Updated",
        dynamic_description: "Local Number has been successfully Updated.",
      })
    } else
      setSalert({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Editted?.message,
      })
  }

  return (
    <>
      {salert.success_dlg ? (
        <SweetAlert
          success
          title={salert.dynamic_title}
          onConfirm={() => setSalert({ success_dlg: false })}
        >
          {salert.dynamic_description}
        </SweetAlert>
      ) : null}

      {salert.error_dlg ? (
        <SweetAlert
          error
          title={salert.dynamic_title}
          onConfirm={() => setSalert({ error_dlg: false })}
        >
          {salert.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Edit Local Number</ModalHeader>
          <Form onSubmit={handleLocalNumberEdit}>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <FormGroup className="select2-container">
                    <Label>Local Number: {info?.localNumber}</Label>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-email-Input">
                      International Mobile Number
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-international-number-Input"
                      value={info?.destination}
                      onChange={handleChange}
                      name="destination"
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-nickname-Input">Nickname</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name="nickname"
                      value={info?.nickname}
                      id="formrow-name-Input"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                color="primary"
              >
                {loading ? "Saving" : "Save"}
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </>
  )
}

EditLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EditLocalNumber
