import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import validate from "validate.js"
// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

import { forgotPassword } from "helpers/requests"

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState(null)

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const data = {
      orgId: "939001",
      email: formState.values.email,
      passwordResetUrl: "https://vomoz-dev.netlify.app/reset-password/",
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }
    console.log(data)
    if (formState.isValid) {
      setLoading(true)

      const res = await forgotPassword({ ...data })
      setLoading(null)
      if (res.success === false) {
        setLoading(null)
      } else {
        setTimeout(() => {
          window.location.href = "/"
        }, 3000)
      }
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }))
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Forgot Password</h5>
                        {/* <p>Re-Password with Skote.</p> */}
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      Enter your Email and instructions will be sent to you!
                    </div>
                    <Form
                      className="form-horizontal"
                      action="dashboard"
                      name="password-reset-form"
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <FormGroup>
                        <Label for="useremail">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          id="useremail"
                          placeholder="Enter your email"
                          helperText={
                            hasError("email") ? formState.errors.email[0] : null
                          }
                          error={hasError("email")}
                          onChange={handleChange}
                          type="email"
                          value={formState.values.email || ""}
                        />
                      </FormGroup>

                      <FormGroup className="row mb-0">
                        <Col xs={12} className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? "Sending..." : "Send"}
                          </button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link
                    to="page-login"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword
