import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import { verifyMobile } from "helpers/requests"
// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const MobileVerification = () => {
  const [mobile, setMobile] = useState("")
  const [loading, setLoading] = useState(null)
  const [location, setLocation] = useState("")

  const handleVerification = async () => {
    const data = {
      mobile,
      orgId: "939001",
      sendSms: "true",
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }

    const res = await verifyMobile(data)
    console.log(res)
    if (res.success === false) {
      setLoading(null)

      console.log("Failed")
    } else {
      console.log("Success")
      localStorage.setItem("vomoz-code", res.data.code)
      localStorage.setItem("vomoz-mobile", mobile)
      setTimeout(() => {
        window.location.href = "/verify"
      }, 5000)
    }
  }
  useEffect(() => {
    const getLocation = async () => {
      const response = await axios.get(`https://ipapi.co/ip/`)
      const locationData = await axios.get(
        `https://ipapi.co/${response.data}/json`
      )
      setLocation(locationData.data.country)
    }
    getLocation()
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages my-0 pt-sm-0">
        <Container>
          <Row
            className="justify-content-center"
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Verification</h5>
                        <p>Verify Mobile Number</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-5">
                  <div
                    className="p-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form
                      className="form-horizontal"
                      action="dashboard"
                      name="password-reset-form"
                      method="post"
                    >
                      <FormGroup>
                        <Label for="useremail" className="ml-2">
                          Enter Mobile Number
                        </Label>
                        <Col xs={12}>
                          <PhoneInput
                            country={location.toLowerCase()}
                            value={mobile}
                            onChange={setMobile}
                            inputProps={{
                              name: "mobile",
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Col xs={12} className="text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={handleVerification}
                          >
                            {loading ? "Sending" : "Send PIN"}
                          </button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already Verified?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login here
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MobileVerification
