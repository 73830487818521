import React, { useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import profileImg from "assets/images/profile-img.svg"
import logoImg from "assets/images/logo.svg"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" },
    ],
  },
]

const BecomeAPartner = () => {
  const [part, setPart] = useState("")

  const handleSelect = part => {
    setPart({ part })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Partnership"} breadcrumbItem={"Join"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Vomoz Value Added Partnership Program
                        </h5>
                        <p>
                          Do you want to become a Vomoz's Reseller or a brand
                          Ambassador or a Distributor or a Corporate Referral?
                          Please fill the form below and one of our business
                          development Directors will contact you.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      // onValidSubmit={handleValidSubmit}
                    >
                      {/* {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                      {console.log(props.user)}
                      {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{props.registrationError}</Alert>
                      ) : null} */}

                      <div className="form-group">
                        <AvField
                          name="name"
                          label="Full Name"
                          className="form-control"
                          placeholder="Full Name"
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          type="email"
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="mobile"
                          label="Mobile Number"
                          type="number"
                          required
                          placeholder="Mobile number"
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          type="select"
                          name="select"
                          label="Partnership Type"
                          helpMessage="How do you want to represent us?"
                        >
                          <option>Partner</option>
                          <option>Vendpr</option>
                          <option>Vending Matchin</option>
                          <option>Vacation</option>
                          <option>More</option>
                        </AvField>
                      </div>
                      <div className="form-group">
                        <AvField
                          type="textarea"
                          name="message"
                          rows={6}
                          label="Additional Information"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By submitting you agree to the Vomoz{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BecomeAPartner.propTypes = {
  t: PropTypes.any,
}

export default BecomeAPartner
