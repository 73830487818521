import React, { useState, useRef, useEffect } from "react"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import { Row, Col, CardBody, Card, Container } from "reactstrap"
import validate from "validate.js"
// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

import { SignUp } from "helpers/requests"

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  mobile: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 10,
      maximum: 15,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: "is required" },
  },
}

const Register = () => {
  const [loading, setLoading] = useState(null)

  let verified = useRef()

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  useEffect(() => {
    let mobile = localStorage.getItem("vomoz-mobile")
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        mobile,
      },
      touched: {
        ...formState.touched,
        mobile,
      },
    }))
  }, [])

  const handleSubmit = async event => {
    event.preventDefault()

    if (formState.isValid) {
      // window.location.replace("/");
      const data = {
        ...formState.values,
        orgId: "939001",
        partnerCode: "nextlevel",
        promoCode: "launch20",
        userIpAddress: "127.0.0.1",
        userDeviceType: "Windows 10",
        userBrowserType: "Chrome",
        userRefererUrl: "www.google.com",
      }

      const res = await SignUp(data)
      if (res.success === false) {
        setLoading(null)
      } else {
        setTimeout(() => {
          window.location.href = "/login"
        }, 5000)
      }
      console.log(res)
    }
    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }))
  }

  useEffect(() => {
    verified.current = localStorage.getItem("vomoz-verified")
    console.log(verified)
    if (verified.current !== "true") {
      window.location.href = "/"
    }
  }, [])

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Sign Up</h5>
                        <p>Get your VomozFlex account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm className="form-horizontal">
                      <div className="form-group">
                        <AvField
                          name="firstName"
                          label="First Name"
                          required
                          placeholder="Enter your first name"
                          helperText={
                            hasError("firstName")
                              ? formState.errors.firstName[0]
                              : null
                          }
                          error={hasError("firstName")}
                          onChange={handleChange}
                          type="text"
                          value={formState.values.firstName || ""}
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="lastName"
                          label="Last Name"
                          required
                          placeholder="Enter your last name"
                          helperText={
                            hasError("lastName")
                              ? formState.errors.lastName[0]
                              : null
                          }
                          error={hasError("lastName")}
                          onChange={handleChange}
                          type="text"
                          value={formState.values.lastName || ""}
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="mobile"
                          label="Mobile Number"
                          required
                          placeholder="Enter your mobile number"
                          helperText={
                            hasError("mobile")
                              ? formState.errors.mobile[0]
                              : null
                          }
                          error={hasError("mobile")}
                          onChange={handleChange}
                          type="number"
                          value={formState.values.mobile || ""}
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter your email"
                          type="email"
                          required
                          helperText={
                            hasError("email") ? formState.errors.email[0] : null
                          }
                          error={hasError("email")}
                          onChange={handleChange}
                          value={formState.values.email || ""}
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter your password"
                          helperText={
                            hasError("password")
                              ? formState.errors.password[0]
                              : null
                          }
                          error={hasError("password")}
                          onChange={handleChange}
                          value={formState.values.password || ""}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          required
                          placeholder="Confirm your password"
                          helperText={
                            hasError("confirmPassword")
                              ? formState.errors.confirmPassword[0]
                              : null
                          }
                          error={hasError("confirmPassword")}
                          onChange={handleChange}
                          value={formState.values.confirmPassword || ""}
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {loading ? "Signing Up" : "Sign Up"}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign up using</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          {/*<li className="list-inline-item">*/}
                          {/*  <Link*/}
                          {/*    to="#"*/}
                          {/*    className="social-list-item bg-info text-white border-info"*/}
                          {/*  >*/}
                          {/*    <i className="mdi mdi-twitter"/>*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Vomoz{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link
                    to="/pages-login"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register

// After Signing Up

//  <Row className="justify-content-center">
//             <Col md={8} lg={6} xl={5}>
//               <Card>
//                 <CardBody>
//                   <div className="p-2">
//                     <div className="text-center">
//                       <div className="avatar-md mx-auto">
//                         <div className="avatar-title rounded-circle bg-light">
//                           <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
//                         </div>
//                       </div>
//                       <div className="p-2 mt-4">
//                         <h4>Verify your email</h4>
//                         <p>
//                           We have sent you verification email{" "}
//                           <span className="font-weight-semibold">
//                             example@abc.com
//                           </span>
//                           , Please check it
//                         </p>
//                         <div className="mt-4">
//                           <a
//                             href="index.html"
//                             className="btn btn-success w-md"
//                           >
//                             Verify email
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </CardBody>
//               </Card>
//               <div className="mt-5 text-center">
//                 <p>
//                   Did't receive an email ?{" "}
//                   <a href="#" className="font-weight-medium text-primary">
//                     {" "}
//                     Resend{" "}
//                   </a>{" "}
//                 </p>
//                 <p>© {new Date().getFullYear()} Vomoz 4.0</p>
//               </div>
//             </Col>
//           </Row>
