import React, { useState } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Form,
  Table,
  Button,
  CardSubtitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import AddAuthCountry from "./components/addAuthCountry"

import images from "assets/images"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { team } from "./data"

const AuthorizedCountries = () => {
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <AddAuthCountry isOpen={open} toggle={toggleModal} />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Countries"} breadcrumbItem={"Authorized"} />
          <Row className="justify-content-center">
            <Col lg={7}>
              <Row className="mb-2">
                <Col sm="4"></Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                      onClick={toggleModal}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      Add Country
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Authorized Countries</CardTitle>

                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <tbody>
                        {map(team, (member, k) => (
                          <tr key={"_member_" + k}>
                            <td style={{ width: "50px" }}>
                              {member.img !== "Null" ? (
                                <img
                                  src={images[member.img]}
                                  className="rounded-circle avatar-xs"
                                  alt=""
                                />
                              ) : (
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                                    {member.name.charAt(0)}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="font-size-14 m-0">
                                <Link to="" className="text-dark">
                                  {member.name}
                                </Link>
                              </h5>
                            </td>
                            <td>
                              <div>
                                {map(member.skills, (skill, key) => (
                                  <Link
                                    to="#"
                                    className="badge badge-primary font-size-11 mr-1"
                                    key={"_skill_" + key}
                                  >
                                    {skill.name}
                                  </Link>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AuthorizedCountries.propTypes = {
  t: PropTypes.any,
}

export default AuthorizedCountries
