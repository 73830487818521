import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Form,
  CardSubtitle,
  Collapse,
  Button,
  CardFooter,
} from "reactstrap"
import { Link } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const PinlessNumber = () => {
  const [drop, setDrop] = useState(false)

  const t_col5 = () => {
    setDrop(!drop)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Number"} breadcrumbItem={"Pinless Number"} />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>PINLess dialing is a freedom</CardTitle>
                  <CardSubtitle className="mb-3">
                    A few years ago, we used to use a whole page to just explain
                    what "PINLess dialing" means! Today, everybody knows it.
                    However, for those of us who are still living in a cave,
                    PINLess dialing simply means making international calls from
                    your phone without the need of entering PIN numbers!
                  </CardSubtitle>
                  <CardSubtitle>
                    The system recognizes your phone number and simply give you
                    a VIP access to make calls with no hassel!
                  </CardSubtitle>
                  <CardSubtitle>
                    Add your home, work (direct line), cell, etc to your account
                    at no extra charge - Just for your utmost convenience!
                  </CardSubtitle>
                  <CardTitle></CardTitle>

                  <AvForm>
                    Equal to
                    <AvField
                      name="number"
                      label="Enter your mobile number  "
                      placeholder="Enter Only number"
                      type="number"
                      errorMessage="Enter Only Number"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Only Numbers",
                        },
                      }}
                    />
                    <CardFooter>
                      NOTE: Please don't add the international numbers you want
                      to call here! This section is ONLY for the phone numbers
                      you'll be calling from! Please go to "Assign Local
                      Numbers" section to get local (magico) numbers for your
                      international numbers.
                    </CardFooter>
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          Add Pinless Number
                        </Button>{" "}
                        <Button type="reset" color="secondary">
                          Cancel
                        </Button>
                      </div>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Default Collapse</CardTitle>
                  <CardSubtitle className="mb-3">
                    You can use a link with the{" "}
                    <code className="highlighter-rouge">href</code> attribute,
                    or a button with the{" "}
                    <code className="highlighter-rouge">data-target</code>{" "}
                    attribute. In both cases, the{" "}
                    <code className="highlighter-rouge">
                      {" "}
                      data-toggle="collapse"
                    </code>{" "}
                    is required.
                  </CardSubtitle>

                  <p>
                    <Link
                      to="#"
                      onClick={t_col5}
                      style={{ cursor: "pointer" }}
                      className="btn btn-primary mo-mb-2"
                    >
                      Link with href{" "}
                    </Link>{" "}
                    &nbsp;
                    <button
                      onClick={t_col5}
                      className="btn btn-primary mo-mb-2"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      Button with data-target
                    </button>
                  </p>
                  <Collapse isOpen={drop}>
                    <Card>
                      <CardBody>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. Nihil anim
                        keffiyeh helvetica, craft beer labore wes anderson cred
                        nesciunt sapiente ea proident.
                      </CardBody>
                    </Card>
                  </Collapse>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PinlessNumber.propTypes = {
  t: PropTypes.any,
}

export default PinlessNumber
