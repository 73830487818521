import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="menu-title">{this.props.t("Menu")}</li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/dashboard">
                      <i className="bx bx-home-circle mr-2" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                    </Link>
                  </li>
                  {/* Section two */}
                  <li className="menu-title">{this.props.t("Account")}</li>
                  <li className="nav-item">
                    <Link to="/account/buy-credit" className="nav-link =">
                      <i className="bx bx-purchase-tag mr-2" />
                      {this.props.t("Buy Credit")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/transfer-credit" className="nav-link =">
                      <i className="bx bx-transfer mr-2" />
                      {this.props.t("Transfer Credit")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/calls-log" className="nav-link =">
                      <i className="bx bx-history mr-2" />
                      {this.props.t("Calls Log")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/pinless-number" className="nav-link =">
                      <i className="bx bxs-contact mr-2" />
                      {this.props.t("Pinless Numbers")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/local-numbers" className="nav-link =">
                      <i className="bx bx-map-pin mr-2" />
                      {this.props.t("Local Numbers")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/follow-me" className="nav-link =">
                      <i className="bx bx-map mr-2" />
                      {this.props.t("Follow Me Numbers")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/access-numbers" className="nav-link =">
                      <i className="bx bx-phone mr-2" />
                      {this.props.t("Access Numbers")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/account/authorized-countries"
                      className="nav-link ="
                    >
                      <i className="bx bx-globe mr-2" />
                      {this.props.t("My Authorized Countires")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/voucher-recharge" className="nav-link =">
                      <i className="bx bx-gift mr-2" />
                      {this.props.t("Voucher Recharge")}
                    </Link>
                  </li>
                  {/* Section Three */}
                  <li className="menu-title">{this.props.t("Services")}</li>
                  <li className="nav-item">
                    <Link to="/account/rates" className="nav-link =">
                      <i className="bx bx-user-circle mr-2" />
                      {this.props.t("Rates")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/contact" className="nav-link =">
                      <i className="bx bx-support mr-2" />
                      {this.props.t("Contact")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/news-press" className="nav-link =">
                      <i className="bx bx-news mr-2" />
                      {this.props.t("New/Press Releases")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/partner" className="nav-link =">
                      <i className="bx bx-file mr-2" />
                      {this.props.t("Become a Partner")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/social" className="nav-link =">
                      <i className="bx bx-share-alt mr-2" />
                      {this.props.t("Follow Us")}
                    </Link>
                  </li>
                  {/* Section Four */}
                  <li className="menu-title">{this.props.t("Settings")}</li>
                  <li className="nav-item">
                    <Link to="/account/pin" className="nav-link =">
                      <i className="bx bx-cog mr-2" />
                      {this.props.t("PIN")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/update-email" className="nav-link =">
                      <i className="bx bx-message mr-2" />
                      {this.props.t("Update Email")}
                    </Link>
                  </li>{" "}
                  <li className="nav-item">
                    <Link to="/account/change-password" className="nav-link =">
                      <i className="bx bx-lock mr-2" />
                      {this.props.t("Change Password")}
                    </Link>
                  </li>{" "}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any
}

export default withRouter(withTranslation()(Navbar))
